<template>
  <div class="content">
    <div class="tuiguangPage flex-center">
      <span class="star">*</span>
      <span class="select-course">活动名称</span>
      <el-input
        class="box"
        placeholder="请输入活动名称"
        maxlength="16"
        show-word-limit
        v-model="ruleForm.name"
      ></el-input>
    </div>
    <div class="tuiguangPage">
      <span class="star">*</span>
      <span class="select-course">活动详情页</span>
      <div class="tuiguangEditor">
        <editor class="editor" v-model="ruleForm.introduction"></editor>
      </div>
    </div>
    <!-- <div style="margin-bottom: 20px">
      <span class="tip">
        提示：系统默认使用课程详情的介绍作为落地页展示内容，如需修改可直接编辑，保存后不会作用到原课程详情
      </span>
    </div> -->
    <div class="tuiguangPage event-item">
      <span class="star">*</span>
      <span class="star"></span>
      <span class="select-course">
        事件类型
        <helpIcon
          style="margin-left: 6px"
          content="表单提交：</br>抖音/头条--表单提交(form)</br>
            百度--表单提交成功（CT=3）</br></br>
商品购买：</br>抖音/头条--商品购买(shopping)</br>
            百度--订单提交成功（CT=14）</br></br>
添加企业微信：</br>抖音/头条--微信_添加企业微信(work_wechat_added)</br>
            百度--微信加粉成功（CT=79） "
        ></helpIcon>
      </span>
      <el-radio v-model="ruleForm.type" label="1" :disabled="!!$route.query.id">
        表单提交
      </el-radio>
      <el-radio v-model="ruleForm.type" label="2" :disabled="!!$route.query.id">
        商品购买
      </el-radio>
      <el-radio v-model="ruleForm.type" label="3" :disabled="!!$route.query.id">
        添加企业微信
      </el-radio>
      <div class="prompt-msg">提示：创建后不支持修改类型</div>
    </div>
    <!--表单提交-->
    <div class="form-box" v-if="ruleForm.type == 1">
      <div
        class="form-item mb20"
        v-for="(item, index) in ruleForm.list"
        :key="item._id"
      >
        <div class="flex-center">
          <div class="left">
            <div class="flex-center">
              <div class="required">
                <template v-if="item.not_null == 1">*</template>
              </div>
              <div class="title">{{ item.qname }}</div>
              <template v-if="'is_verification' in item">
                <el-switch
                  class="dx-switch"
                  :value="item.is_verification"
                  active-value="1"
                  inactive-value="2"
                  @change="isVerificatio($event, index)"
                ></el-switch>
                <div class="title ml10">
                  短信验证{{ item.is_verification == 1 ? '已开启' : '已关闭' }}
                </div>
              </template>
            </div>
            <!-- <el-input
            class="input"
            :placeholder="`请输入${item.qname}`"
          ></el-input> -->
          </div>
          <div class="right flex-center btns">
            <el-checkbox
              :value="item.not_null"
              true-label="1"
              false-label="2"
              style="margin-top: 2px;"
              :disabled="item.is_verification == 1"
              @change="item.not_null = arguments[0]"
            >
              必填
            </el-checkbox>
            <div class="space mr20"></div>
            <el-button
              type="text"
              :disabled="item.qattr == 1"
              @click="edit(item)"
            >
              编辑
            </el-button>
            <div class="space">|</div>
            <el-button
              type="text"
              :disabled="index == 0"
              @click="upDownMove(index, index - 1)"
            >
              上移
            </el-button>
            <div class="space">|</div>
            <el-button
              type="text"
              :disabled="index == ruleForm.list.length - 1"
              @click="upDownMove(index, index + 1)"
            >
              下移
            </el-button>
            <div class="space">|</div>
            <el-popconfirm
              title="确定删除此选项？"
              @confirm="removeQuestion(index)"
            >
              <el-button
                slot="reference"
                type="text"
                :disabled="item.qattr == 1"
              >
                删除
              </el-button>
            </el-popconfirm>
          </div>
        </div>
        <!--单选样式1-->
        <template v-if="item.style == 1">
          <div class="option1" v-for="v in item.option_content" :key="v.id">
            {{ v.result }}
          </div>
        </template>
        <!--单选样式2-->
        <template v-if="item.style == 2">
          <el-select v-model="style2" class="option2">
            <el-option
              v-for="v in item.option_content"
              :key="v.id"
              :label="v.result"
              :value="v.result"
            ></el-option>
          </el-select>
        </template>
      </div>
      <!--填空输入框-->
      <div class="flex-center form-input" v-if="customForm">
        <el-input
          ref="questionInput"
          v-model.trim="question.qname"
          @keyup.enter.native="saveQuestion"
          placeholder="请输入表单项题目，20字以内，回车键保存"
        ></el-input>
        <div class="flex-center">
          <el-checkbox
            :value="question.not_null"
            :checked="question.not_null == 1"
            true-label="1"
            false-label="2"
            style="margin-right: 140px;"
            @change="question.not_null = arguments[0]"
          >
            必填
          </el-checkbox>
          <el-button type="text" @click="saveQuestion">保存</el-button>
          <el-button type="text" @click="toggleCustomForm1">取消</el-button>
        </div>
      </div>
      <!--默认添加 单选 填空-->
      <div class="flex-center form-btn" v-else>
        + 自定义表单：
        <el-button type="text" @click="toggleCustomForm1">
          填空
        </el-button>
        <div class="space">|</div>
        <el-button type="text" @click="toggleCustomForm2">
          单选
        </el-button>
      </div>
    </div>
    <div
      class="form-box"
      style="position: relative"
      v-else-if="ruleForm.type == 2"
    >
      <div class="content-add-course">
        <el-button
          class="btn"
          icon="el-icon-plus"
          @click="addCourseClick"
          v-if="course.length == 0"
        >
          选择商品
        </el-button>
        <div v-for="(item2, index) in course" :key="index">
          <div class="contain_course" v-if="course.length !== 0">
            <div class="course_img">
              <img :src="item2.photo || item2.cover_img" alt="" />
            </div>
            <div class="name_price">
              <div :title="item2.name" class="name">{{ item2.name }}</div>
              <div class="course-price">
                <span>
                  {{ '￥' + item2.price }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="delCourse" v-if="course.length !== 0">
          <el-button type="text" @click="moveSaveClass">移除重选</el-button>
        </div>
      </div>
      <div v-if="course.length !== 0 && !course[0].open_class_id">
        <span
          :class="{ tip: true, red: course[0].delete_time > 0 }"
          style="margin: 10px 0 0 110px"
        >
          {{
            course[0].course_id
              ? 'delete_time' in course[0]
                ? course[0].delete_time == 0
                  ? course[0].is_show == 1
                    ? '提示：该课程已设置展示到网校'
                    : '提示：该课程已设置不在网校展示'
                  : '提示：课程已被删除，请移除重选课程！'
                : course[0].is_show == 1
                ? '提示：该课程已设置展示到网校'
                : '提示：该课程已设置不在网校展示'
              : ''
          }}
        </span>
      </div>
    </div>
    <div class="tuiguangPage flex-center">
      <span class="star"></span>
      <span class="select-course">按钮名称</span>
      <el-input
        class="box"
        :placeholder="ruleForm.type == 1 ? '提交内容' : '立即报名'"
        maxlength="10"
        show-word-limit
        v-model="ruleForm.button_name"
      ></el-input>
    </div>

    <div class="tuiguangPage flex-center" v-if="ruleForm.type == 2">
      <span class="star"></span>
      <span class="select-course">tips提示文字</span>
      <el-input
        class="box"
        placeholder="请输入tips提示文字"
        maxlength="50"
        show-word-limit
        v-model="ruleForm.tips"
      ></el-input>
    </div>

    <div class="registration-guidance">
      <span class="star">*</span>
      <span class="select-course">报名后引导</span>

      <div class="box registration-guidance-content">
        <div class="guidance-type">
          <el-radio-group v-model="formData.url_type">
            <el-radio :label="1">二维码</el-radio>
            <el-radio :label="2">链接</el-radio>
          </el-radio-group>
        </div>
        <!-- 二维码引导 -->
        <div v-if="formData.url_type==1" class="guidance-content-ercode">
          <div class="yindaoyu">
            <span class="star" style="margin-top: 12px">*</span>
            <span
              class="select-course"
              style="margin-right: -17px; margin-top: 12px"
            >
              引导语
            </span>
            <div class="yindao-help">
              <el-input
                v-model="formData.inputGuidance"
                maxlength="30"
                show-word-limit
                placeholder="请务必添加助教微信，方便及时接受开课通知和联系资料！"
              ></el-input>
            </div>
          </div>
          <div class="scond flex-center">
            <div class="erweima">
              <span class="star" style="margin-top: 12px">*</span>
              <span class="select-course" style="margin-top: 12px">二维码</span>
            </div>
            <div class="rightSelectP">
              <div class="qrCode">
                <el-image :src="formData.fileList" fit="cover" alt="未添加图片">
                  <div slot="error" class="image-tip">
                    <span>未添加图片</span>
                  </div>
                </el-image>
              </div>
              <div class="selectPic">
                <div>
                  <span style="font-size: 12px">
                    建议尺寸500*500px，JPG、PNG格式，小于2M
                  </span>
                </div>
                <div style="margin-top: 24px">
                  <el-button @click="selectPicture">选择图片</el-button>
                </div>
              </div>
            </div>
  
            <div class="shili">
              <el-popover
                popper-class="popoverBackB"
                placement="top-start"
                trigger="hover"
                :close-delay="1000"
              >
                <img src="@ass/img/1.4.7/img_ydsl.png" />
                <div slot="reference">查看示例</div>
              </el-popover>
            </div>
          </div>
  
          <div class="tuiguangPage flex-center mb30 mt30">
            <span class="star"></span>
            <span class="select-course">获取微信昵称</span>
            <el-switch
              :value="ruleForm.is_wxname"
              active-value="1"
              inactive-value="2"
              @change="ruleForm.is_wxname = arguments[0]"
            ></el-switch>
            <div style="color: #666; font-size: 14px; margin-left: 10px">
              {{
                ruleForm.is_wxname == 1
                  ? '已开启，开启开关可以定义内容进行引导'
                  : '已关闭'
              }}
            </div>
          </div>
          <template v-if="ruleForm.is_wxname == 1">
            <div class="tuiguangPage flex-center">
              <span class="star"></span>
              <span class="select-course">按钮文字</span>
              <el-input
                class="box"
                placeholder="添加老师微信"
                maxlength="20"
                show-word-limit
                v-model="ruleForm.text2"
              ></el-input>
            </div>
            <div class="tuiguangPage flex-center">
              <span class="star"></span>
              <span class="select-course">提示文字</span>
              <el-input
                class="box"
                placeholder="点击添加老师微信后，即可查看二维码"
                maxlength="30"
                show-word-limit
                v-model="ruleForm.text1"
              ></el-input>
            </div>
            <!-- <div class="tuiguangPage flex-center">
              <span class="star"></span>
              <span class="select-course"></span>
              <el-popover
                placement="top-start"
                trigger="hover"
                :close-delay="1000"
                class="prompt-text"
                style="margin: -10px 0px 15px;"
              >
                <img src="@ass/img/img_hqwxcksl.png" />
                <div slot="reference">查看示例</div>
              </el-popover>
            </div> -->
          </template>
          <div class="third flex-center mt30">
            <span class="star " style="width:5px"></span>
            <span class="select-course">备注</span>
            <el-input
              type="text"
              placeholder="请输入备注，比如二维码所属人的名字，某某老师"
              v-model="formData.remark"
              maxlength="20"
              show-word-limit
            ></el-input>
          </div>
        </div>
        <!-- 链接 -->
        <div v-if="formData.url_type==2" class="guidance-content-link">
          <div class="yindaoyu">
            <span class="star" style="margin-top: 12px">*</span>
            <!-- <span
              class="select-course"
              style="margin-right: -17px; margin-top: 12px"
            >
              引导语
            </span> -->
            <div class="yindao-help">
              <el-input
                v-model="formData.wx_url"
                placeholder="请输入正确的企业微信二维码链接！"
                :rows="2"
                type="textarea"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footerHasCourse">
      <el-button @click="cancleSaveCourse">取消</el-button>
      <el-button type="primary" @click="saveCourse">保存</el-button>
    </div>

    <!--单选表单--->
    <el-dialog
      title="单选表单（请至少保留两个选项）"
      width="800px"
      :visible.sync="customForm2"
      :close-on-click-modal="false"
      @close="dialogClose"
    >
      <div class="tuiguangPage flex-center" style="margin: -20px 50px 0 0;">
        <span class="star">*</span>
        <span class="select-course">表单项名称</span>
        <el-input
          class="box"
          placeholder="请输入表单项名称"
          maxlength="20"
          show-word-limit
          v-model="question.qname"
        ></el-input>
      </div>
      <div
        class="tuiguangPage flex-center"
        v-for="(item, index) in question.option_content"
        :key="item.id"
      >
        <span class="star">*</span>
        <span class="select-course">选项</span>
        <el-input
          class="box"
          placeholder="请输入选项内容"
          maxlength="20"
          show-word-limit
          v-model="item.result"
        ></el-input>
        <el-button
          type="text"
          class="ml20"
          style="color:#FF3535"
          @click="deleteOptionContent(index)"
        >
          删除
        </el-button>
      </div>
      <div class="tuiguangPage flex-center">
        <span class="star"></span>
        <span class="select-course"></span>
        <div
          @click="
            question.option_content.push({ result: '', id: Math.random() })
          "
          class="prompt-text"
        >
          + 增加选项
        </div>
      </div>
      <div class="tuiguangPage flex-center">
        <span class="star"></span>
        <span class="select-course">样式选择</span>
        <div class="flex-center mr20">
          <el-radio v-model="question.style" label="1">普通单选</el-radio>
          <el-popover
            popper-class="popoverBackB"
            placement="top-start"
            trigger="hover"
            :close-delay="1000"
            class="prompt-text"
          >
            <img src="@ass/img/mg_ptdx.png" />
            <div slot="reference">查看示例</div>
          </el-popover>
        </div>
        <div class="flex-center">
          <el-radio v-model="question.style" label="2">下拉框单选</el-radio>
          <el-popover
            popper-class="popoverBackB"
            placement="top-start"
            trigger="hover"
            :close-delay="1000"
            class="prompt-text"
          >
            <img src="@ass/img/mg_lbdx.png" />
            <div slot="reference">查看示例</div>
          </el-popover>
        </div>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button @click="toggleCustomForm2">取消</el-button>
        <el-button type="primary" @click="saveQuestion2">确定</el-button>
      </div>
    </el-dialog>

    <networkschoolSelect
      @selectChangenetworkschool="closeGoodsConfirm"
      @singleselectcontentval="updateCourse"
      title="选择商品"
      v-if="addCourseBool"
      :dialogstatus.sync="addCourseBool"
      :template="12"
      :singleselect="true"
      :id_type="5"
    ></networkschoolSelect>
    <!-- 自定义添加图片 -->
    <addpicture
      @locationUpload="locationUpload"
      :type="1"
      :size="2"
      :dialogstatus.sync="dialogstatus"
      ref="addpicture"
      @list="list"
      @close="dialogstatus = false"
    ></addpicture>
    <!--本地上传弹窗-->
    <upload
      v-if="uploadStart"
      @uploadConfirmClose="uploadConfirmClose"
      @close="uploadConfirmClose"
      :typeselection="1"
      :title="'上传图片(多选)'"
      :uploadType="2"
      :list="group_id1"
    ></upload>
  </div>
</template>

<script>
import networkschoolSelect from '@cm/base/networktypeSelect/networkschoolSelect'
import editor from '@cm/editor'
import addpicture from '@cm/base/addpicture'
//本地上传弹窗
import upload from '@cm/base/UploadtextFile'
import { mapState, mapMutations } from 'vuex'
// import axios from '@/request/axios'

export default {
  data() {
    return {
      addCourseBool: false,
      course_id: '', //选择的课程id
      course: [], //选择的课程
      token: '',
      //基础信息
      ruleForm: {
        name: '',
        describe: '开通VIP，畅学多门高级课程',
        cover_img: '',
        is_show: '1',
        introduction: '',
        button_name: '',
        tips: '购买成功后，老师将会尽快联系你',
        text2: '添加老师微信',
        text1: '点击添加老师微信后，即可查看二维码',
        type: '1',
        is_wxname: '1',
        list: [
          {
            qname: '手机号',
            _id: Math.random(),
            qattr: 1,
            not_null: '1',
            is_verification: '1',
            qtype: 1,
          },
          {
            qname: '真实姓名',
            _id: Math.random(),
            qattr: 2,
            not_null: '2',
            qtype: 1,
          },
        ],
      },
      question: {
        qname: '',
        not_null: '1',
        type: '',
        option_content: [
          { result: '', id: Math.random() },
          { result: '', id: Math.random() },
        ],
        style: '1',
      },
      customForm: false,
      customForm2: false,
      clearRadioSelect: 0,
      style2: '',
      editId: '',
      addPic: '',
      // 选择图片弹窗
      dialogstatus: false,
      createOrEditActive: 1,
      formData: {
        // 引导类型
        url_type:1,
        editorData: '',
        inputGuidance: '请务必添加助教微信，方便及时接受开课通知和联系资料！',
        // 引导语链接
        wx_url:'',
        fileList: '',
        remark: '',
      },
    }
  },
  computed: {
    ...mapState('netSchoolDecration', [
      'changgePicturestatus',
      'group_id1',
      'uploadStart',
    ]),
    //添加图片弹窗控制
    addpicturestatus() {
      return this.changgePicturestatus
    },
  },
  watch: {
    ruleForm: {
      handler(val) {
        this.formData.editorData = val.introduction
      },
      deep: true,
    },
  },
  components: {
    networkschoolSelect,
    upload,
    editor,
    addpicture,
  },
  methods: {
    ...mapMutations('netSchoolDecration', [
      'uploadStartChange',
      'changgePicture',
      'groupid1Change',
    ]),

    isVerificatio(event, index) {
      if (event == 1) {
        this.ruleForm.list[index].is_verification = event
        this.ruleForm.list[index].not_null = '1'
      } else {
        this.ruleForm.list[index].is_verification = event
      }
    },

    addCourseClick() {
      this.addCourseBool = true
    },

    moveSaveClass() {
      this.course = []
      this.clearRadioSelect = 1
    },

    closeGoodsConfirm() {
      this.addCourseBool = false
    },

    updateCourse(row) {
      this.course = [row]
    },

    cancleSaveCourse() {
      this.$confirm('当前内容未保存，是否退出？', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        callback: action => {
          if (action == 'confirm') {
            //退出编辑
            this.$router.push({
              path: '/tuiguang',
            })
          }
        },
      })
    },

    saveCourse() {
      const course = this.course[0] ? this.course[0] : {}

      // 按钮名称设置一个默认值
      this.ruleForm.button_name =
        this.ruleForm.button_name.trim() ||
        (this.ruleForm.type == 1 ? '提交内容' : '立即购买')
      this.ruleForm.text2 = this.ruleForm.text2.trim() || '添加老师微信'
      this.ruleForm.text1 =
        this.ruleForm.text1.trim() || '点击添加老师微信后，即可查看二维码'
      this.ruleForm.text3 = '长按二维码识别，添加老师微信'
      if(this.formData.url_type==2&&!this.formData.wx_url){
        this.$root.prompt({
          msg: '请输入正确的企业微信二维码链接！',
          type: 'error',
        })
        return
      }

      if (this.$route.query.id) {
        //编辑活动
        this.$http({
          url: '/CourseAdvertising/editAdvertising',
          data: Object.assign({}, this.ruleForm, {
            target_type: course.open_class_id ? 2 : 1,
            target_id: course.open_class_id || course.course_id,
            content: this.ruleForm.introduction,
            purchased_keywords: this.formData.inputGuidance,
            purchased_qrcode: this.formData.fileList,
            remark: this.formData.remark,
            ca_id: parseInt(this.$route.query.id),
            url_type:this.formData.url_type,
            wx_url:this.formData.url_type==2?this.formData.wx_url:'',
          }),
          callback: () => {
            this.course = []
            this.clearRadioSelect = 1

            //跳转到列表页
            this.$router.push({
              path: '/tuiguang',
            })
          },
        })
      } else {
        //创建活动
        this.$http({
          url: '/CourseAdvertising/addAdvertising',
          data: Object.assign({}, this.ruleForm, {
            // 1 直播课 2 公开课
            target_type: course.open_class_id ? 2 : 1,
            target_id: parseInt(
              'course_id' in course ? course.course_id : course.open_class_id
            ),
            remark: this.formData.remark,
            content: this.ruleForm.introduction,
            purchased_keywords: this.formData.inputGuidance,
            purchased_qrcode: this.formData.fileList,
            url_type:this.formData.url_type,
            wx_url:this.formData.url_type==2?this.formData.wx_url:'',
          }),
          callback: () => {
            this.course = []
            this.clearRadioSelect = 1

            //跳转到列表页
            this.$router.push({
              path: '/tuiguang',
            })
          },
        })
      }
    },

    //本地上传弹窗控制
    locationUpload() {
      this.uploadStartChange(true)
    },

    // 本地上传弹窗关闭
    uploadConfirmClose() {
      this.$refs.addpicture.renew()
      this.uploadStartChange(false)
    },

    list(val) {
      this.formData.fileList = val.url
    },

    selectPicture() {
      this.dialogstatus = !this.dialogstatus
    },

    getEditorData() {
      if (this.$route.query.id) {
        //点击编辑获取编辑页面数据
        this.$http({
          url: `/CourseAdvertising/editAdvertising?ca_id=${this.$route.query.id}`,
          callback: ({ data }) => {
            this.course = [data.course]
            this.ruleForm = Object.assign({}, data, {
              introduction: data.content,
              list: data.list
                ? data.list.map(item =>
                    _.merge({}, item, { _id: Math.random() })
                  )
                : [],
            })
            this.formData.inputGuidance = data.purchased_keywords
            this.formData.fileList = data.purchased_qrcode
            this.formData.remark = data.remark
            this.formData.url_type = Number(data.url_type)
            this.formData.wx_url = data.wx_url
          },
        })
      }
    },

    changeTitle() {
      if ('id' in this.$route.query) {
        this.$root.$children[0].childPageOptions[1].name = '编辑'
        // console.log('this',this)
      }
    },

    // 上移 下移
    upDownMove(index, newIndex) {
      let optionlist = this.ruleForm.list
      const oldData = optionlist[newIndex]
      optionlist.splice(newIndex, 1, optionlist[index])
      optionlist.splice(index, 1, oldData)
    },

    // 编辑表单
    edit(val) {
      if (val._id == this.question._id) return
      this.question = _.merge({}, val)
      this.editId = this.question._id
      console.log(this.question)
      // 区分填空 还是 单选
      if (this.question.qtype == 1) {
        this.toggleCustomForm1()
      } else {
        this.toggleCustomForm2()
      }
    },

    // 删除题目
    removeQuestion(index) {
      this.ruleForm.list.splice(index, 1)
    },

    // 删除单选表单
    deleteOptionContent(index) {
      if (this.question.option_content.length <= 2) {
        this.$root.prompt({
          msg: '不可删除，请至少保留两个选项！',
          type: 'warning',
        })
      } else {
        this.question.option_content.splice(index, 1)
      }
    },

    // 填空表单
    toggleCustomForm1() {
      this.customForm = !this.customForm
      if (this.customForm) {
        // 设置为填空属性
        this.question.qtype = 1
        this.$nextTick(() => {
          this.$refs.questionInput.focus()
        })
      } else {
        this.question = {
          qname: '',
          not_null: '1',
          qtype: '',
          option_content: [
            { result: '', id: Math.random() },
            { result: '', id: Math.random() },
          ],
          style: '1',
        }
        this.editId = ''
      }
    },

    // 单选表单
    toggleCustomForm2() {
      this.customForm2 = !this.customForm2
      if (this.customForm2) {
        // 设置为单选属性
        this.question.qtype = 2
      } else {
        this.dialogClose()
      }
    },

    // 关闭弹窗
    dialogClose() {
      this.question = {
        qname: '',
        not_null: '1',
        qtype: '',
        option_content: [
          { result: '', id: Math.random() },
          { result: '', id: Math.random() },
        ],
        style: '1',
      }
      this.editId = ''
    },

    // 单选加选项
    saveQuestion2() {
      if (!this.question.qname.trim()) {
        this.$root.prompt('请输入表单项名称')
        return
      } else if (this.question.option_content.some(v => !v.result.trim())) {
        this.$root.prompt('请输入选项内容')
        return
      }
      const res = _.merge({}, this.question, {
        qname: this.question.qname,
        _id: Math.random(),
        qattr: 2,
      })
      if (this.editId) {
        const index = this.ruleForm.list.findIndex(
          item => item._id == this.editId
        )
        this.ruleForm.list.splice(index, 1, res)
      } else {
        this.ruleForm.list.push(res)
      }
      this.toggleCustomForm2()
    },

    // 填空加选项
    saveQuestion() {
      if (this.question.qname) {
        const res = _.merge({}, this.question, {
          qname: this.question.qname,
          _id: Math.random(),
          qattr: 2,
        })
        if (this.editId) {
          const index = this.ruleForm.list.findIndex(
            item => item._id == this.editId
          )
          this.ruleForm.list.splice(index, 1, res)
        } else {
          this.ruleForm.list.push(res)
        }
        this.toggleCustomForm1()
      }
    },
  },
  mounted() {
    this.getEditorData()
    this.changeTitle()
  },
}
</script>

<style lang="scss" scoped>
.content {
  margin: 20px 20px 0px 20px;
  padding: 30px 20px 0px 20px;
  min-height: 390px;
  position: relative;
}
.btn {
  height: 40px;
  width: 140px;
  margin-top: 10px;
}
.content-add-course {
  display: flex;
  height: 60px;
  line-height: 60px;
}
.content-add-course div:last-child {
}
.star {
  color: #ff3535;
  margin-right: 10px;
  font-size: 14px;
}
.select-course {
  width: 100px;
  font-size: 14px;
}
.footerHasCourse {
  margin-top: 40px;
  text-align: center;
  padding-bottom: 20px;
}
.footerNoCourse {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  // margin: auto;
}
::v-deep .el-dialog__body {
  // padding: 0;
  .selectDialogBox {
    // padding: 10px 20px 0 20px;
  }
}
.course_img img {
  width: 100px;
  height: 60px;
  // border: 1px solid #d8d8d8;
}
.contain_course {
  display: flex;
}
.name_price {
  margin-left: 10px;
  height: 60px;
  font-size: 14px;
  margin-top: -20px;
}
.name {
  width: 518px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.course-price {
  margin-top: -16px;
}
.course-price span:first-child {
  width: 50px;
  color: #ff3535;
  margin-right: 15px;
}
// .course-price span:last-child {
//   color: #999999;
//   text-decoration: line-through;
// }
.tip {
  display: block;
  font-size: 12px;
  color: #666666;
  margin-left: 116px;
}
.tuiguangPage {
  margin-top: 30px;
  display: flex;
}
.delCourse {
  position: absolute;
  right: 20px;
}
tuiguangEditor {
  height: 655px;
  width: 312px;
}
.error-tip {
  color: #ff3535;
  font-size: 12px;
}
.editor {
  margin-bottom: 18px;
  width: 655px;
}
.editor-error {
  border: 1px solid #ff3535;
  margin-bottom: 0;
}
.registration-guidance {
  margin-top: 10px;
  display: flex;
  margin-top: 30px;
  position: relative;
}
.box {
  width: 658px;
}
.registration-guidance-content {
  background: #f5f5f5;
  padding: 30px 20px 56px 20px;
  display: inline-block;
  .guidance-type {
    margin-bottom: 10px;
  }
  .guidance-content-ercode,.guidance-content-link {
    > div:first-child {
      display: flex;
    }
    > div:last-child {
      display: flex;
    }
  }
}
.qrCode {
  height: 140px;
  width: 140px;
  z-index: 10;
  display: inline-block;
  .el-image {
    height: 140px;
    width: 140px;
    background: #ffffff;
  }
  .el-image .image-tip > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    color: #666666;
  }
}
.qrCode img {
  height: 140px;
  width: 140px;
  line-height: 140px;
  text-align: center;
  font-size: 12px;
  color: #666666;
}
.selectPic {
  margin-left: 20px;
}
.rightSelectP {
  display: flex;
}
.erweima {
  width: 100px;
}
.yindaoyu {
}
.yindao-help {
  width: 500px;
}
.scond {
  margin-top: 30px;
}
.shili {
  position: absolute;
  font-size: 14px;
  color: #0aa29b;
  left: 720px;
  bottom: 20px;
  cursor: pointer;
}
.popoverBackB .popper__arrow::after {
  background: #000 !important;
}
.red {
  color: #ff3535;
}
.formClass {
  ::v-deep .el-form .el-form-item > .el-form-item__label:before {
    margin-right: 10px;
  }
}
.event-item {
  .prompt-msg {
    font-size: 12px;
    color: #666666;
    margin-left: 0;
  }
}
.form-box {
  width: 658px;
  padding-left: 116px;
  margin-top: 20px;
  .form-item {
    border-bottom: 1px solid #eaeaea;
    .left {
      flex: 1;
      .required {
        color: #ff3535;
        margin-right: 5px;
        vertical-align: top;
        font-size: 14px;
      }
      .title {
        font-size: 14px;
        color: #333333;
      }
      .dx-switch {
        margin-left: 20px;
      }
      .input {
        margin-top: 10px;
        ::v-deep .el-input__inner {
          height: 54px;
          border: 0;
          padding-left: 0;
          line-height: 54px;
        }
      }
    }
    .right {
      .space {
        color: $color;
        font-size: 14px;
        margin: 0 10px;
      }
    }
  }
  .option1 {
    font-size: 14px;
    color: #666666;
    margin: 0 0 14px 10px;
  }
  .option2 {
    width: 420px;
    display: block;
    margin: 10px 0 20px 10px;
  }
}
.form-input {
  margin: 10px 0 8px 0;
  border-bottom: 1px solid #ff3535;
  ::v-deep .el-input__inner {
    height: 54px;
    border: 0;
    padding-left: 0;
    line-height: 54px;
  }
}
.form-btn {
  font-size: 14px;
  color: #333;
  .space {
    color: #0aa29b;
    line-height: 14px;
    margin: -2px 10px 0;
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.prompt-text {
  font-size: 14px;
  color: rgb(10, 162, 155);
  cursor: pointer;
}
</style>
